import { Component, ElementRef, Inject, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { DfSideNavCollapseService, DfSideNavService } from '@design-factory/design-factory';
import { HelpConfig, PageHelpConfig } from 'src/app/models/help-config';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HELP_CONFIG_INJECTOR } from 'src/app/services/helpconfig.token';
import { SubscriptionListService } from 'src/app/services/subscription-list.service';

@Component({
  selector: 'app-navbar-header',
  templateUrl: './navbar-header.component.html',
  styleUrls: ['./navbar-header.component.scss']
})
export class NavbarHeaderComponent {
  @Input() isSubscriptionSet: boolean;
  @Input() currentPageId: string;
  @Input() user: any;
  public isHelpCollapsed = true;

  @Output() helpCollapsedEmitter = new EventEmitter<boolean>();

  @ViewChild("iframe") iframe: ElementRef<HTMLIFrameElement>;
  readonly URL_VALIDATOR: RegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]amadeus-hospitality+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/igm;
  public languageCode: string = "";

  public accountDisplays: any[] = [
    {
      id: 'changepassword',
      label: $localize `Change Password`,
    },
    {
      id: 'logout',
      label: $localize `Sign out`,
    }
  ];

  constructor(public readonly sideNavService: DfSideNavService,
    public readonly sideNavCollapseService: DfSideNavCollapseService,
    private subListService: SubscriptionListService,
    public authService: AuthenticationService,
    @Inject(HELP_CONFIG_INJECTOR) public helpConfig: HelpConfig) {
      
    }

    handleOnHelpClick() {
      if (this.isHelpCollapsed) {
        this.helpCollapsedEmitter.emit(this.isHelpCollapsed);
        this.isHelpCollapsed = !this.isHelpCollapsed;
        this.lookupHelpTopic(this.currentPageId);
      } else {
        this.helpCollapsedEmitter.emit(this.isHelpCollapsed);
        this.isHelpCollapsed = !this.isHelpCollapsed;
      }
    }
  
    private lookupHelpTopic(pageId: string): void {
      let pageConfig: PageHelpConfig = this.helpConfig.pageHelpConfigurations.find(pc => pc.pageId === pageId)
      this.setHelpUrl(pageConfig);
    }
  
    private setHelpUrl(pageConfig: PageHelpConfig): void {
      if (pageConfig) {       
          this.iframe.nativeElement.contentWindow.location.replace(`${this.helpConfig.baseHelpUrl}${pageConfig.helpTopicId}${this.languageCode}`);       
      }
      else {
        this.iframe.nativeElement.contentWindow.location.replace(`${this.helpConfig.baseHelpUrl}${this.languageCode}`);
      }
    }
  
    public isHelpConfigValid(config: any): boolean {
      if (!this.helpConfig
        || !this.helpConfig.pageHelpConfigurations
        || this.helpConfig.pageHelpConfigurations.length < 1
        || !this.helpConfig.baseHelpUrl
        || !this.validateUrl(this.helpConfig.baseHelpUrl)) {
        return false;
      }
      else {
        return true;
      }
    }
  
    private validateUrl(baseUrl: string): boolean {
      return this.URL_VALIDATOR.test(baseUrl);
    }

    listenAccountItemClick(event) {
      if (event != null){
        //In both cases, we need the user to sign out and clear the app data
        this.subListService.subscriptions$.next(null);
        if (event.id === 'logout') {
          this.authService.logout();
        }
        if (event.id === 'changepassword'){
          this.authService.changePassword();
        }
      }
    }
}
