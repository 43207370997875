import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { toastStyles } from 'src/app/utils/constants';

@Component({
  selector: 'app-alert-toast',
  templateUrl: './alert-toast.component.html',
  styleUrls: ['./alert-toast.component.scss']
})
export class AlertToastComponent implements OnInit {
  @Input() message: string;
  @Input() type: string;

  static _success = new Subject<string>();
  static successMessage?: string;
  static alertType: any;
  static alertStyle: any;

  constructor() { }

  ngOnInit(): void {
  }

  static showToast(message: string, alertType: string, alertStyle = toastStyles.big) {
    this._success.subscribe(message => (this.successMessage = message));
    this._success.pipe(debounceTime(5000)).subscribe(() => (this.successMessage = undefined));
    this.alertType = alertType;
    this.alertStyle = alertStyle;
    this._success.next(message);
  }

  get getSucess() {
    return AlertToastComponent.successMessage;
  }

  get getAlert() {
    return AlertToastComponent.alertType;
  }

  get getAlertStyle() {
    return AlertToastComponent.alertStyle;
  }

  set setSucess(value: any) {
    AlertToastComponent.successMessage = value;
  }

}
