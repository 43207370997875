<app-spinner id="responder" *ngIf="isProposalProvidersLoading"></app-spinner>
<div class="modal-header">
<h2 class="modal-title" [style.display]="isVisible ? 'block' : 'none'" i18n>Choose Proposal</h2>
<div class="button-container">
  <button
  [style.display]="isVisible ? 'block' : 'none'"
  type="button"
  class="close"
  aria-label="Close"
  id="btnClose"
  (click)="activeModal.close(crossClickData)">
    <span class="fa-times"></span>
  </button>
</div>
</div>

<div *ngIf="errorMessage!=''" class="divProposalChoiceError">
  <label class="proposalChoiceErrorTitle" i18n>There was an error trying to launch the proposal application.</label>
  <div class="divProposalChoiceErrDetails">
    <label i18n>Details:</label>
  </div>
  <label class="errorMessage" id="lblErrorText">{{errorMessage}}</label>
</div>
<div *ngIf="errorMessage==''">
  <div *ngFor="let proposalProvider of proposalProviderData index as i;">
    <div class="Proposalchannels">
      <div *ngIf="i === 1" (click)="ThirdPartyChannelCliked(proposalProvider.id)" [title]="proposalProvider.url!==''? '':'Respond Directly to the Planner'" >
        <div id="div1" class="divContents">
          <h3>
            <label id="Proposalchannelsname1" class="cursorPointer">{{proposalProvider.name}}</label>
          </h3>
        </div>
        <label id="Proposalchannelstext1" class="cursorPointer" i18n>You may also respond directly to the planner using
          {{proposalProvider.name}}</label>
      </div>

      <div *ngIf="i === 0" (click)="(proposalProvider.url !== '')? SourceChannelCliked():ThirdPartyChannelCliked(proposalProvider.id)" 
      [title]="proposalProvider.url!==''? '':'Respond directly to a RFP using the channel application'" >
        <div  class="divContents">
          <h3>
            <label id="Proposalchannelsname2"  class="cursorPointer">{{proposalProvider.name}}</label>
          </h3>
        </div>
        <label id="Proposalchannelstext2"  class="cursorPointer" i18n>The primary contact of the RFP requires that you respond in
          {{proposalProvider.name}}</label>
      </div>
    </div>
  </div>
</div>