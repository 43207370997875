import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Attachment, CreateMessageFlow, CreateMessageFormModel, CreateMessageInfo, ResponseInstructionType, UiFieldReadonlyStates } from '../../models/create-message.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CreateMessageService } from '../../services/create-message.service';
import { MbRole } from 'src/app/models/mb-role';
import { AppConfigService } from 'src/app/helpers/configuration/app-config.service';
import { Guid } from 'guid-typescript';
import { PutReassignRfpRequestEnvelope, RFPStatusChange, RFPStatusChangeRequest, RFPStatusChangeRequestModel, RfpStatusConstants } from 'projects/rfp-list/src/app/models/rfp-detail.model';
import { RfpDetailsService } from 'projects/rfp-list/src/app/services/rfp-details.service';
import { RfpProposalDetailsService } from 'projects/rfp-list/src/app/services/rfp-proposal-details.service';
import { dateTimeFormatOptionsForDateOnly } from 'projects/shared/pagination/src/app/models/dateTimeFormatOptions';
import { RFPError } from 'projects/rfp-list/src/app/constants/rfp-error.constants';
import { toastStyles, toastTypes } from 'src/app/utils/constants';
import { FileUploadModel } from '../../models/file-upload.model';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttachmentService } from 'src/app/services/attachment.service';
import { CreateAttachmentResponseModel } from 'src/app/models/attachment.model';
import { AlertPopupService, AlertPopupConfig } from 'src/app/services/alert-popup.service';
import { AlertToastComponent } from '../alert-toast/alert-toast.component';


@Component({
  selector: 'app-create-message-page',
  templateUrl: './create-message-page.component.html',
  styleUrls: ['./create-message-page.component.scss']
})
export class CreateMessagePageComponent implements OnInit {
  rfpId: Guid;
  proposalId: Guid;
  submitted = false;
  dateTimeFormatOptionsForDateOnly = dateTimeFormatOptionsForDateOnly;
  errorMessage: string;
  alertType: any;
  alertStyle: any;
  displayFailureAlert = false;
  uploadSucess1:boolean = false;
  uploadSucess2:boolean = false;
  uploadSucess3:boolean = false;
  uploadFaliure1:boolean=false;
  uploadNotValidate1:boolean=false;
  uploadCommitted1:boolean=false;
  uploadNotValidate2:boolean=false;
  uploadCommitted2:boolean=false;
  uploadNotValidate3:boolean=false;
  uploadCommitted3:boolean=false;
  uploadFaliure2:boolean=false;
  uploadFaliure3:boolean=false;
  fileUploadModel:FileUploadModel;
  files: Attachment[] = [];
  fileSize = 0;
  totalFileSize = 0;
  private statuscheckIntervalForFile2;
  private statuscheckIntervalForFile3;
  private statuscheckIntervalForFile1;
  sendProposalAPICallStartTime: Date;
  sendMessageAPIStartTime: Date;
  message: string;
  
  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private readonly location: Location,
    private appConfigService: AppConfigService,    
    private router: Router,
    private readonly alertPopupService: AlertPopupService,
    private createMessageService: CreateMessageService,
    private rfpDetailsService: RfpDetailsService,
    private rfpProposalDetailsService: RfpProposalDetailsService,
    private attachmentService: AttachmentService,
  ) {
    this.LoadAlerts();
    this.maxFileSizeInBytes = parseInt(this.appConfigService.getConfig().maxFileSizeInBytes);
    this.maxFileNameLength = parseInt(this.appConfigService.getConfig().maxFileNameLength);
    this.allowedFileTypes = this.appConfigService.getConfig().allowedFileTypes;
    let currentDate = new Date(Date.now());
    let nextDate = currentDate;
    this.defaultDate = new Date(currentDate).toISOString().slice(0, 10);
    nextDate.setDate(currentDate.getDate() + 1);
    this.minCustomDate = "";
  }

  @ViewChild('attachment1') attachment1;
  @ViewChild('attachment2') attachment2;
  @ViewChild('attachment3') attachment3;

  selectOptions = ['1', '2', '3', '4'];
  dropdownSelected: (number | undefined)[] = [undefined, undefined, undefined];
  turnDownReasons: string[] = [];
  turnDownSelectedReason: string = '';
  turnDownComments: string = `I need to cancel my booking for [date and time]. Something came up, and I can't make it.

  Booking Details:
  
  Booking ID: [Your Booking ID]
  Name: [Your Name]
  Date and Time: [Scheduled Date and Time]
  I know there might be some cancellation rules, and I'm cool with any charges or whatever. Just let me know what I need to do to cancel it.
  
  Sorry for the hassle, and thanks for understanding!`;

  public createMessageTitle: string;

  public uiFieldReadonlyStates: UiFieldReadonlyStates;

  public createMessageForm: UntypedFormGroup;
  public messageFlows = CreateMessageFlow;
  public createMessageDetails: CreateMessageInfo;

  private readonly maxFileSizeInBytes: number;
  public readonly allowedFileTypes: string;
  private readonly maxFileNameLength: number;

  public readonly defaultDate: string;
  public readonly minCustomDate: string;

  private currentDate = new Date();

  public alerts: Array<IAlert> = [];
  private backup: Array<IAlert>;

  private richTextValue: any;
  public isReadonlySelfAward : boolean;
  public isMessagePosting: boolean = false;
  public isFileUploading1: boolean = false;
  public isFileUploading2: boolean = false;
  public isFileUploading3: boolean = false;
  public isEndPointIntegrated: boolean = false;
  

  ngOnInit(): void {
    this.createMessageDetails = this.createMessageService.getCreateMessageData();
    this.rfpId = this.createMessageDetails.CommonInfo.RfpId
    this.proposalId = this.createMessageDetails.CommonInfo.ProposalId
    this.isReadonlySelfAward =this.createMessageDetails.MessageFlow === this.messageFlows.SelfAward ? true : false;
    

    if (this.createMessageDetails === null) {
      this.router.navigate(['notfound']);
    }

    //This should be removed once the API is integrated
    if(this.createMessageDetails.MessageFlow === this.messageFlows.Redirect){
      this.isEndPointIntegrated = true;
    }
    this.setPageTitle();
    this.loadReadOnlyControls();
    this.buildForm();
  }

  private buildForm() {
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.Proposal):
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To, disabled: this.uiFieldReadonlyStates.To }, [this.validateEmail()]),
          Cc: new UntypedFormControl({ value: this.createMessageDetails.ProposalInfo?.Cc, disabled: this.uiFieldReadonlyStates.Cc }, [this.validateEmail()]),
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
      case (this.messageFlows.TurnDown):  
      this.turnDownReasons = this.createMessageDetails.TurnDownInfo.TurnDownReasons;      
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To, disabled: this.uiFieldReadonlyStates.To }, [this.validateEmail()]),
          Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
          SystemRecipients: this.createMessageDetails.TurnDownInfo.SystemRecipients,
          TurnDownReasons: '',
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
      case (this.messageFlows.SelfAward):
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl(),
          Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
        case (this.messageFlows.Reassign):
          this.createMessageForm = this.formBuilder.group({
            To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To,  disabled: this.uiFieldReadonlyStates.To}),
            ToEmail: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.ToEmail,  disabled: this.uiFieldReadonlyStates.ToEmail}),
            Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
            Attachment1: [],
            Attachment2: [],
            Attachment3: [],
            Location: this.createMessageDetails.CommonInfo.LocationName,
            From: this.createMessageDetails.CommonInfo.From
          });
          break;
      default:
        this.createMessageForm = this.formBuilder.group({
          To: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.To, disabled: this.uiFieldReadonlyStates.To }),
          Comments: new UntypedFormControl({ value: this.createMessageDetails.CommonInfo.Comments ?? '', disabled: this.uiFieldReadonlyStates.Comment }),
          Attachment1: [],
          Attachment2: [],
          Attachment3: []
        });
        break;
    }
  }

  onChangesOnRTE(value: string) { // on changes on Rich Text Editor
    this.richTextValue = value;
  }

  private LoadAlerts() {
    this.alerts.push(
      {
        id: alertErrorCode.fileExtension,
        type: 'danger',
        displayAlert: false,
        message: 'The file extension of one or more attachments is not supported. Supported file extensions include TXT, BMP, JPEG, PNG, PDF, XLS, DOC, DOCX, XLSX, JPG, MSG, PPT, PPTX'
      },
      {
        id: alertErrorCode.totalAttachmentSize,
        type: 'danger',
        displayAlert: false,
        message: 'The size of all attached files exceeds the maximum allowed 15MB.'
      },
      {
        id: alertErrorCode.toField,
        type: 'danger',
        displayAlert: false,
        message: 'To field should be specified'
      },
      {
        id: alertErrorCode.toCcField,
        type: 'danger',
        displayAlert: false,
        message: 'To field should be specified along with CC field.'
      },
      {
        id: alertErrorCode.selectReason,
        type: 'danger',
        displayAlert: false,
        message: 'Please select any of the reason.'
      },
      {
        id: alertErrorCode.awardDataEmpty,
        type: 'danger',
        displayAlert: false,
        message: 'Award Date cannot be blank'
      },
      {
        id: alertErrorCode.awardDateInvalid,
        type: 'danger',
        displayAlert: false,
        message: 'Award date should be past current date'
      },
      {
        id: alertErrorCode.InvalidEmail,
        type: 'danger',
        displayAlert: false,
        message: 'Enter Valid Email Address'
      },
      {
        id: alertErrorCode.fileNameLength,
        type: 'danger',
        displayAlert: false,
        message: 'The file name contains greater than the maximum allowed 255 characters.'
      },
    );
    this.backup = this.alerts.map((alert: IAlert) => Object.assign({}, alert));
  }


  //Method mocking data format for all flows
  private getCreateMessageMockDetails(currentDate: Date): CreateMessageInfo {
    return {
      MessageFlow: this.messageFlows.Proposal, // set according to the parent page
      CommonInfo: {
        To: 'TestUser@amadeus.com',
        From: 'LeagueOfShadows@amadeus.com',
        Subject: 'Subject Test',
        Organization: 'Organization Test',
        PrimaryContact: 'Primary Contact Test',
        Comments: this.createMessageDetails.MessageFlow === this.messageFlows.TurnDown ? this.turnDownComments : '',
        RfpId: this.rfpId,
        Person:null
      },
      ProposalInfo: {
        Cc: 'CC Test',
        Date: this.datePipe.transform(currentDate, 'MM/dd/yyyy'),
        AllowResponseInstructionOverride: false,
        RichTextEditorData: '',
        ResponseInstructionType: ResponseInstructionType.RespondToPrimaryContact
      },
      UserRole: MbRole.Supervisor,
      TurnDownInfo: {
        TurnDownReasons: this.turnDownReasons,
        SystemRecipients: 'System recipients test',
      },
      SelfAwardInfo: {
        Date: this.datePipe.transform(currentDate, 'MM/dd/yyyy'),
      }
    }
  }

  async onSubmit(form: UntypedFormGroup) {
    this.isMessagePosting = true;
    this.errorMessage="";
    if(this.createMessageDetails.MessageFlow === this.messageFlows.TurnDown || this.createMessageDetails.MessageFlow === this.messageFlows.Proposal)
    {
    this.submitted = true;
    if (this.createMessageForm.invalid) {
      this.isMessagePosting = false;
      this.displayErrorMessage(alertErrorCode.InvalidEmail);
      return;
    }
  }
     if (
      this.validateInputFields(form) &&
      this.validateTurnDownReason()
    ) {
      let formData = this.getFormModel(form, this.files);
      switch (this.createMessageDetails.MessageFlow) {
        case this.messageFlows.Proposal: {
          await this.sendProposal(form,this.files);
          break;
        }
        case this.messageFlows.TurnDown: {
          await this.UpdateRFPStatus(formData, form, RfpStatusConstants.TurnedDown, this.files);
          break;
        }
        case this.messageFlows.SelfAward: {
          await this.UpdateRFPStatus(formData, form, RfpStatusConstants.SelfAwarded, this.files);
          break;
        }
        case this.messageFlows.Reassign: {
          await this.ReassignRFPOwner(form, RfpStatusConstants.Reassign, this.files);
          break;
        }
        default: {
          break;
        }
      }
      
    } else {
      this.isMessagePosting = false;
      //this.createMessageDetails = this.getCreateMessageMockDetails(this.currentDate);
    }

}

  private async UpdateRFPStatus(formData: CreateMessageFormModel, form: UntypedFormGroup, rfpStatus: string, files: Attachment[]) {
    let rfpStatusChangeObj: RFPStatusChange = {
      Status: rfpStatus,
      Comment: formData.Comments,
      ReasonId: formData.turnDownReason,
      StartDate: formData.SelfAwardDate,
    };

    let rfpStatusChangeRequest: RFPStatusChangeRequest = {
      rfpStatusChange: rfpStatusChangeObj,
    };
    let selfAwardRequestModel: RFPStatusChangeRequestModel = {
      data: rfpStatusChangeRequest,
    };
    await this.rfpDetailsService
      .updateRfpStatus(this.rfpId.toString(), selfAwardRequestModel)
      .toPromise()
      .then((response) => {
        if (response) {
          this.PostMessage(form, files);
        } else {
          this.isMessagePosting=false;
          this.displayError($localize `Something went wrong. Please try again later.`);
          return;
        }
      })
      .catch((err) => {
        this.isMessagePosting=false;
        this.handleUpdateServiceError(err) ;
        return;
      });
  }

  private async ReassignRFPOwner(form: UntypedFormGroup, rfpStatus: string,files: Attachment[]) {
    var reassignmentrequestModel: PutReassignRfpRequestEnvelope = {
      data: {
        assignmentMethod: rfpStatus, 
        newUserId: this.createMessageDetails.CommonInfo.ToId.toString()
      }
    }
    await this.rfpDetailsService
      .reassignRfp(this.rfpId.toString(), reassignmentrequestModel)
      .toPromise()
      .then((response) => {
        if (response) { 
          this.PostMessage(form, files);
        } else {
          this.isMessagePosting=false;
          this.displayError($localize `Something went wrong. Please try again later.`);
          return;
        }
      })
      .catch((err) => {
        this.isMessagePosting=false;
        this.handleUpdateServiceError(err) ;
        return;
      });
  }

  private PostMessage(form: FormGroup, files: Attachment[]) {
    this.sendMessageAPIStartTime = new Date();
    this.createMessageService.CreateMessage(this.getFormModel(form, files)).then(response => {
      if (response) {
        console.log('Send Message API call duration: ', (new Date().getTime() - this.sendMessageAPIStartTime.getTime())/1000 + ' seconds');
        this.isMessagePosting=false;
          switch(this.createMessageDetails.MessageFlow){
            case this.messageFlows.Proposal:
              setTimeout(() => {
                this.location.back();
              }, 2000);
              console.log('Send proposal operation overall duration: ', (new Date().getTime() - this.sendProposalAPICallStartTime.getTime())/1000 + ' seconds');
              this.message = $localize `Proposal sent successfully!`;
              this.alertType = toastTypes.success;
              AlertToastComponent.showToast(this.message,  this.alertType, toastStyles.small);
              break;
            case this.messageFlows.UpdateRfp:
              setTimeout(() => {
                this.location.back();
              }, 2000);
              this.message = $localize `RFP updated successfully!`;
              this.alertType = toastTypes.success;
              AlertToastComponent.showToast(this.message,  this.alertType, toastStyles.small);
              break;
            case this.messageFlows.TurnDown:
              setTimeout(() => {
                this.router.navigate(['/rfplist']);
              }, 2000);
              this.message = $localize `RFP turned down successfully!`;
              this.alertType = toastTypes.success;
              AlertToastComponent.showToast(this.message,  this.alertType, toastStyles.small);
              break;
            case this.messageFlows.SelfAward:
              setTimeout(() => {
                this.router.navigate(['/rfplist']);
              }, 2000);
              this.message = $localize `RFP awarded to self successfully!`;
              this.alertType = toastTypes.success;
              AlertToastComponent.showToast(this.message,  this.alertType, toastStyles.small);
              break;
            case this.messageFlows.Redirect:
              setTimeout(() => {
                this.router.navigate(['/rfplist']);
              }, 2000);
              this.message = $localize `RFP redirected successfully!`;
              this.alertType = toastTypes.success;
              AlertToastComponent.showToast(this.message,  this.alertType, toastStyles.small);
              break;
            case this.messageFlows.Reassign:
              setTimeout(() => {
                this.router.navigate(['/rfplist']);
              }, 2000);
              this.message = $localize `RFP reassigned successfully!`;
              this.alertType = toastTypes.success;
              AlertToastComponent.showToast(this.message,  this.alertType, toastStyles.small);
              break;
          }
      }
      else {
        this.isMessagePosting=false;
        this.displayError( $localize `Something went wrong. Please try again later.`);
        return;
      }
    }).catch(err => {
      this.isMessagePosting=false;
      this.handleUpdateServiceError(err);
      return;
    });
  }

  getFormModel(form: UntypedFormGroup, files: Attachment[]): CreateMessageFormModel {
    let formModel: CreateMessageFormModel;
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.Proposal):
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Cc: form.get('Cc')?.value,
          RichText: this.richTextValue,
          Attachments: files,
          proposalDate: new Date(Date.now()).toDateString(),
          Comments: form.get('Comments')?.value ?form.get('Comments')?.value : null,
          SelfAwardDate: null,
          turnDownReason: null,
          systemRecipients: null,
          proposalId: this.proposalId,
          ProposalLastSentDate : this.createMessageDetails.CommonInfo.ProposalLastSentDate
        }
        break;
      case (this.messageFlows.SelfAward):
        var defaultDate = document.getElementById("DefaultSelfAwardDate") as HTMLInputElement;
        var customDate = document.getElementById("CustomSelfAwardDate") as HTMLInputElement;
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Attachments: files,
          SelfAwardDate: defaultDate.checked ? defaultDate.value : this.datePipe.transform(customDate.value, 'MM/dd/yyyy'),
          Comments: form.get('Comments')?.value,
          Cc: null,
          RichText: null,
          turnDownReason: null,
          systemRecipients: null,
          rfpId: this.rfpId
        }
        break;
      case (this.messageFlows.TurnDown):
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Cc: null,
          Attachments: files,
          Comments: form.get('Comments')?.value,
          systemRecipients: form.get('SystemRecipients').value,
          turnDownReason: this.turnDownSelectedReason,
          SelfAwardDate: null,
          RichText: null,
          rfpId: this.rfpId
        }
        break;
        case (this.messageFlows.Reassign):
          formModel = {
            flow: this.createMessageDetails.MessageFlow,
            To: form.get('To').value,
            ToEmail: form.get('ToEmail').value,
            Cc: null,
            Attachments: files,
            Comments: form.get('Comments')?.value,
            systemRecipients: null,
            turnDownReason: null,
            SelfAwardDate: null,
            RichText: null,
            rfpId: this.rfpId,
            From:form.get('From').value,
            LocationName:form.get('Location').value
          }
          break;
      default:
        formModel = {
          flow: this.createMessageDetails.MessageFlow,
          To: form.get('To').value,
          Comments: form.get('Comments')?.value,
          Attachments: files,
          Cc: null,
          RichText: null,
          SelfAwardDate: null,
          systemRecipients: null,
          turnDownReason: null,
        }
        break;      
    }
    formModel.Person = this.createMessageDetails.CommonInfo.Person;
    formModel.From=this.createMessageDetails.CommonInfo.From;
    formModel.Organization = this.createMessageDetails.CommonInfo.Organization;
    formModel.Subject = this.createMessageDetails.CommonInfo.Subject;
    return formModel;
  }

  validateInputFields(form: UntypedFormGroup): boolean {
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Proposal) {
      if (!(form.get('To').value)) {
        this.displayErrorMessage(alertErrorCode.toCcField);
        return false;
      }
    }
    if (this.createMessageDetails.MessageFlow == this.messageFlows.TurnDown) {
      if (!(form.get('To').value)) {
        this.displayErrorMessage(alertErrorCode.toField);
        return false;
      }
    } 
    if (!(form.get('To').value) && this.createMessageDetails.MessageFlow !== this.messageFlows.SelfAward) {
      this.displayErrorMessage(alertErrorCode.toField);
      return false;
    }
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Reassign) {
      if (!(form.get('To').value)) {
        this.displayErrorMessage(alertErrorCode.toField);
        return false;
      }
    }
    if (this.createMessageDetails.MessageFlow === this.messageFlows.SelfAward && !this.checkSelfAwardDate()) {
      var customDate = document.getElementById("CustomSelfAwardDate") as HTMLInputElement;
      if (!customDate.value) {
        this.displayErrorMessage(alertErrorCode.awardDataEmpty);
        return false;
      }
      var parsedDate = new Date(Date.parse(customDate.value));
      if (this.currentDate >= parsedDate) {
        this.displayErrorMessage(alertErrorCode.awardDateInvalid);
        return false;
      }
    }
    return true;
  }

  validateFileNameLength(file: File): boolean {
    if (file.name.length > this.maxFileNameLength) {
      this.totalFileSize -= this.fileSize;
      this.displayErrorMessage(alertErrorCode.fileNameLength);
      return false;
    }
    this.alerts.filter(alert => alert.id === alertErrorCode.fileNameLength)[0].displayAlert = false;
    return true;
  }

  validateFilesSize(): boolean {
    if (this.totalFileSize > this.maxFileSizeInBytes) {
      this.totalFileSize -= this.fileSize;
      this.displayErrorMessage(alertErrorCode.totalAttachmentSize);
      return false;
    }
    this.alerts.filter(alert => alert.id === alertErrorCode.totalAttachmentSize)[0].displayAlert = false;
    return true;
  }

  validateFileType(file: File): boolean {
    if (file) {
      const splitFile = file.name.split(/(?=\.)/);
      if (splitFile.length > 1) {
        const extension = splitFile.pop()?.toLowerCase();
        const allowedExtensions = this.allowedFileTypes.split(',').map((item) => {
          this.alerts.filter(alert => alert.id === alertErrorCode.fileExtension)[0].displayAlert = false;
          return item.trim();
        });
        if (allowedExtensions.some((value) => value.toLowerCase() === extension)) {
          return true;
        } else {
          this.totalFileSize -= this.fileSize;
          this.displayErrorMessage(alertErrorCode.fileExtension);
          return false;
        }
      }
      else {
        this.displayErrorMessage(alertErrorCode.fileExtension);
        return false;
      }
    }
  }

  validateTurnDownReason(): boolean {
    if (this.createMessageDetails.MessageFlow == this.messageFlows.TurnDown && this.turnDownSelectedReason.length == 0) {
      this.displayErrorMessage(alertErrorCode.selectReason);
      return false;
    }
    return true;
  }

  validateReassignReason(): boolean {
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Reassign && this.turnDownSelectedReason.length == 0) {
      this.displayErrorMessage(alertErrorCode.selectReason);
      return false;
    }
    return true;
  }

  displayErrorMessage(errorCode: number) {
    const filteredAlerts: IAlert[] = this.alerts.filter(alert => alert.id === errorCode);
    if (filteredAlerts.length > 0) {
      filteredAlerts[0].displayAlert = true;
    }
  }

  onReasonSelected() {
    this.alerts.filter(alert => alert.id === alertErrorCode.selectReason)[0].displayAlert = false;
  }

  onToValueChange() {
    if (this.createMessageDetails.MessageFlow == this.messageFlows.Proposal)
      this.alerts.filter(alert => alert.id === alertErrorCode.toCcField)[0].displayAlert = false;
    else{
      this.alerts.filter(alert => alert.id === alertErrorCode.toField)[0].displayAlert = false;
      this.alerts.filter(alert => alert.id === alertErrorCode.InvalidEmail)[0].displayAlert = false;
    }
  }

  async updateFile(event: Event, attachmentNo: string) {
    let file = (event.currentTarget as HTMLInputElement).files[0];
    const unsubscribeForFile1$ = new Subject();
    const unsubscribeForFile2$ = new Subject();
    const unsubscribeForFile3$ = new Subject();

    if (file.type === '' && file.name.endsWith('.msg')) {
      file = new File([file], file.name, { type: 'application/vnd.ms-outlook' });
    } 

    let fileUploadModel = {
      contentType: file.type,
      url: file.webkitRelativePath,
      name: file.name,
      content: ""
    };
    this.fileSize = file.size;
    this.totalFileSize +=  this.fileSize;

    if (this.validateFileType(file) && this.validateFileNameLength(file) && this.validateFilesSize()) {
      const fileContents = await this.readUploadedFileAsDataURL(file);
      let fileContentString = fileContents.toString();
      fileUploadModel.content = fileContentString.substring(fileContentString.indexOf(',') + 1);

      if (attachmentNo === '1') {
        this.uploadSucess1 = false;
        this.uploadFaliure1 = false;
        this.isFileUploading1 = true;
        try{
          await this.createMessageService
              .createAttachment(fileUploadModel)
              .then(response => { 
                 const fileUploadStatusResponse = response as CreateAttachmentResponseModel;
                 this.statuscheckIntervalForFile1 =
                interval(1000)
                .pipe(takeUntil(unsubscribeForFile1$))
                .subscribe(async x => {
                     try{
                                 await this.attachmentService.getAttachmentStatusDetails(fileUploadStatusResponse.data.reference.id)
                                .toPromise()
                                  .then((response) => {
                                  if (response) { 
                                    if (response.data.status === 'Ok')
                                      {
                                        if(!this.statuscheckIntervalForFile1.closed === true){
                                          this.createMessageForm.patchValue({ Attachment1: file });
                                          this.uploadSucess1 = true;
                                          this.isFileUploading1 = false;
                                          this.files.push({id:response.data.id});
                                          this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                          this.statuscheckIntervalForFile1.unsubscribe();
                                        }
                                        
                                      }
                                   else if(response.data.status === 'Failed') {
                                    if(!this.statuscheckIntervalForFile1.closed === true){
                                    this.uploadFaliure1 = true;
                                    this.isFileUploading1 = false;
                                    this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                    this.statuscheckIntervalForFile1.unsubscribe();
                                    }
                                  }
                                  else if(response.data.status === 'NotValid') {
                                    if(!this.statuscheckIntervalForFile1.closed === true){
                                    this.isFileUploading1 = false;
                                    this.uploadNotValidate1 = true;
                                    this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                    this.statuscheckIntervalForFile1.unsubscribe();
                                    }
                                  }
                                  else if(response.data.status === 'Committed') {
                                    if(!this.statuscheckIntervalForFile1.closed === true){
                                    this.isFileUploading1 = false;
                                    this.uploadCommitted1 = true;
                                    this.statuscheckIntervalForFile1.next(); // unsubscribe not working as expected under subscription block
                                    this.statuscheckIntervalForFile1.unsubscribe();
                                    }
                                  }
                                } 
                                }
                                  ,
                               error => { 
                                console.log("Error occourd for getting file status: " + error);
                               }
                              );
                        }
                  catch(e){
                                this.handleUpdateServiceError(e);
                                return;
                          } 
                });    
                });
                }catch (e) { 
                  this.handleUpdateServiceError(e);
                  return; 
              }   
            }
    else if (attachmentNo === '2') {
        this.uploadSucess2 = false;
        this.uploadFaliure2 = false;
        this.isFileUploading2 = true;
        try {
          await this.createMessageService
              .createAttachment(fileUploadModel)
              .then(async response => { 
                const fileUploadStatusResponse = response as CreateAttachmentResponseModel;
                this.statuscheckIntervalForFile2 =
                interval(1000)
                .pipe(takeUntil(unsubscribeForFile2$))
                .subscribe(async x => {
                     try{
                                 await this.attachmentService.getAttachmentStatusDetails(fileUploadStatusResponse.data.reference.id)
                                .toPromise()
                                  .then((response) => {
                                  if (response) { 
                                    if (response.data.status === 'Ok')
                                      {
                                        if(!this.statuscheckIntervalForFile2.closed === true){
                                        this.createMessageForm.patchValue({ Attachment2: file });
                                        this.uploadSucess2 = true;
                                        this.isFileUploading2 = false;
                                        this.files.push({id:response.data.id});
                                        this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                        this.statuscheckIntervalForFile2.unsubscribe();
                                      }
                                    }
                                   else if(response.data.status === 'Failed') {
                                    if(!this.statuscheckIntervalForFile2.closed === true){
                                    this.uploadFaliure2 = true;
                                    this.isFileUploading2 = false;
                                    this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                    this.statuscheckIntervalForFile2.unsubscribe();
                                    }
                                  }
                                  else if(response.data.status === 'NotValid') {
                                    if(!this.statuscheckIntervalForFile2.closed === true){
                                    this.isFileUploading2 = false;
                                    this.uploadNotValidate2 = true;
                                    this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                    this.statuscheckIntervalForFile2.unsubscribe();
                                    }
                                  }
                                  else if(response.data.status === 'Committed') {
                                    if(!this.statuscheckIntervalForFile2.closed === true){
                                    this.isFileUploading2 = false;
                                    this.uploadCommitted2 = true;
                                    this.statuscheckIntervalForFile2.next(); // unsubscribe not working as expected under subscription block
                                    this.statuscheckIntervalForFile2.unsubscribe();
                                    }
                                  }
                                } 
                                }
                                ,
                                error => { 
                                 console.log("Error occourd for getting file status: " + error);
                                }
                              );
                        }
                  catch(e){
                                this.handleUpdateServiceError(e);
                                return;
                          } 
                });  
   
                });
                }catch (e) {  
                  this.handleUpdateServiceError(e) ;
                  return;
            } 
            }
        else {
        this.uploadSucess3 = false;
        this.uploadFaliure3 = false;
        this.isFileUploading3 = true;
        try {
          await this.createMessageService
              .createAttachment(fileUploadModel)
              .then(async response => {   
                const fileUploadStatusResponse = response as CreateAttachmentResponseModel;  
                this.statuscheckIntervalForFile3 =           
                interval(1000)
                .pipe(takeUntil(unsubscribeForFile3$))
                .subscribe(async x => {
                     try{
                                 await this.attachmentService.getAttachmentStatusDetails(fileUploadStatusResponse.data.reference.id)
                                .toPromise()
                                  .then((response) => {
                                  if (response) { 
                                  if (response.data.status === 'Ok')
                                  {
                                    if(!this.statuscheckIntervalForFile3.closed === true){
                                        this.createMessageForm.patchValue({ Attachment3: file });
                                        this.uploadSucess3 = true;
                                        this.isFileUploading3 = false;
                                        this.files.push({id:response.data.id});
                                        this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected for subscription
                                        this.statuscheckIntervalForFile3.unsubscribe();
                                    }
                                 }
                                   else if(response.data.status === 'Failed') {
                                    if(!this.statuscheckIntervalForFile3.closed === true){
                                    this.uploadFaliure3 = true;
                                    this.isFileUploading3 = false;
                                    this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected under subscription block
                                    this.statuscheckIntervalForFile3.unsubscribe();
                                    }
                              }
                              else if(response.data.status === 'NotValid') {
                                if(!this.statuscheckIntervalForFile3.closed === true){
                                this.isFileUploading3 = false;
                                this.uploadNotValidate3 = true;
                                this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile3.unsubscribe();
                                }
                              }
                              else if(response.data.status === 'Committed') {
                                if(!this.statuscheckIntervalForFile3.closed === true){
                                this.isFileUploading3 = false;
                                this.uploadCommitted3 = true;
                                this.statuscheckIntervalForFile3.next(); // unsubscribe not working as expected under subscription block
                                this.statuscheckIntervalForFile3.unsubscribe();
                                }
                              }
                            } 
                          }
                          ,
                          error => { 
                           console.log("Error occourd for getting file status: " + error);
                          }
                        );
                        }
                  catch(e){
                                this.handleUpdateServiceError(e);
                                return;
                          } 
                });
                });
        }catch (e) {  
                  this.handleUpdateServiceError(e) ;
                  return;
      }
    }
  } else {
      if (attachmentNo === '1') {
        this.createMessageForm.patchValue({ Attachment1: null });
        this.attachment1.nativeElement.value = null;
        this.uploadSucess1=false;
        this.uploadFaliure1=false;
      }
      else if (attachmentNo === '2') {
        this.createMessageForm.patchValue({ Attachment2: null });
        this.attachment2.nativeElement.value = null;
        this.uploadSucess2=false;
        this.uploadFaliure2=false;
      }
      else if (attachmentNo === '3') {
        this.createMessageForm.patchValue({ Attachment3: null });
        this.attachment3.nativeElement.value = null;
        this.uploadSucess3=false;
        this.uploadFaliure3=false;
      }
    }
  }
  
  ngOnDestroy(): void {
    if(this.statuscheckIntervalForFile1){
      this.statuscheckIntervalForFile1.unsubscribe();
    }
    if(this.statuscheckIntervalForFile2){
      this.statuscheckIntervalForFile2.unsubscribe();
    }
    if(this.statuscheckIntervalForFile3){
      this.statuscheckIntervalForFile3.unsubscribe();
    }
  }

  readUploadedFileAsDataURL(inputFile) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(inputFile);
    });
  }

  loadReadOnlyControls() {
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.Proposal):
        this.uiFieldReadonlyStates = {
          To: false, //TODO: Add user role check if it is present in classic MB
          Cc: false, //TODO: Add user role check if it is present in classic MB
        }
        break;
      case (this.messageFlows.TurnDown):
        this.uiFieldReadonlyStates = {
          To: false,
          Comment: false
        }
        break;
      default:
        this.uiFieldReadonlyStates = {
          To: true,
          Comment: false
        };
        break;
    }
  }

  setPageTitle() {
    switch (this.createMessageDetails.MessageFlow) {
      case (this.messageFlows.TurnDown):
        this.createMessageTitle = $localize `Message For Turn Down`;
        break;
      case (this.messageFlows.Redirect):
        this.createMessageTitle = $localize `Message For Redirect`;
        break;
      case (this.messageFlows.Proposal):
        this.createMessageTitle = $localize `Message For Propose`;
        break;
      case (this.messageFlows.SelfAward):
        this.createMessageTitle = $localize `Message For Self Award`;
        break;
      case (this.messageFlows.Reassign):
        this.createMessageTitle = $localize `Message For Reassign`;
        break;
      case (this.messageFlows.NewRfp):
        this.createMessageTitle = $localize `Message For New RFP`;
        break;
      case (this.messageFlows.UpdateRfp):
        this.createMessageTitle = $localize `Message For Update RFP`;
        break;
      default:
        this.router.navigate(['notfound']);
        break
    }
  }

  onBackButtonClicked() {
    this.location.back();
  }
  public closeAlert(alert: IAlert) {

    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
    alert.displayAlert = false;
    this.alerts.push(alert);
  }

  public reset() {
    this.alerts = this.backup.map((alert: IAlert) => Object.assign({}, alert));
  }

  checkSelfAwardDate(): boolean {
    var element = document.getElementById('CustomSelfAwardDateId') as HTMLInputElement;
    return element.checked ? null : true;
  }

  onDateTypeChange(e) {
    if(e.srcElement.id==='DefaultSelfAwardDate'){
      this.alerts.filter(alert => alert.id === alertErrorCode.awardDataEmpty)[0].displayAlert = false;
    }
  }
  onCustomSelfAwardDateSelect(e) {
    this.alerts.filter(alert => alert.id === alertErrorCode.awardDataEmpty)[0].displayAlert = false;
  }
  // validating array of emails
  validateEmail() {
    return (control: UntypedFormControl) => {
      var emails = control.value.split(";");
      var regex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      let validEmail = true;

      for (var i = 0; i < emails.length; i++) {
        emails[i] = emails[i].trim();
        if (emails[i] != "") {
          if (!regex.test(emails[i])) {
            validEmail = false;
          }
        }
      }
      return validEmail ? null : { invalid_email: true };
    };
  }

  async sendProposal(formData, files) {
    this.sendProposalAPICallStartTime = new Date();
    await this.rfpProposalDetailsService
      .sendProposal(this.rfpId)
      .toPromise()
      .then((response) => {
        if (response) {
          console.log('sendProposal API call duration: ', (new Date().getTime() - this.sendProposalAPICallStartTime.getTime())/1000 + ' seconds');
          this.PostMessage(formData, files);
        } else {
          this.isMessagePosting=false;
          
          this.displayError($localize `Something went wrong. Please try again later.`);
          return;
        }
      })
      .catch((err) => {
        this.isMessagePosting=false;
        this.handleUpdateServiceError(err);
        return;
      });
  }

  displayError(errorMessage : string)
  {
    this.errorMessage =  errorMessage;
    this.displayFailureAlert = true;
    this.alertType =toastTypes.danger;
    this.alertStyle =toastStyles.big;
  }
  handleUpdateServiceError(err: any) {
      let message : string;
      const errors = err?.error?.errors?.filter(x => x.code);
      if (!errors || !errors.length) return;

      errors.forEach(e => {
        switch (e.code) {
          case RFPError.BAD_REQUEST:
            message = $localize `Oops, something went wrong. Please check your input.`;
            break;
          case RFPError.INTERNAL_SERVER_ERROR:
            message = $localize `Something went wrong. Please try again later.`;
            break;
          default:
            message = $localize `Something went wrong. Please try again later.`;
        }
      });
  
      message =  message == "" || message == null ? $localize `Something went wrong. Please try again later.` : message;
      this.displayError(message);
  }
}

export interface IAlert {
  id: number;
  type: string;
  message?: string;
  displayAlert: boolean;
}

export enum alertErrorCode {
  fileExtension = 1,
  totalAttachmentSize,
  toCcField,
  selectReason,
  awardDataEmpty,
  awardDateInvalid,
  toField,
  InvalidEmail,
  fileNameLength
}


